import * as constants from './Constants.js';

import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import BingoBoard from './BingoBoard.js';

class ViewGame extends React.Component {
  constructor(props) {
    super(props);
    this.gameId = this.props.match.params.gameId;
    this.playerViewId = this.props.match.params.playerViewId;
    this.state = {
      gameName: '',
      playerName: '',
      squares: []
    };
    this.onTextChange = this.onTextChange.bind(this);
    this.onSquareClick = this.onSquareClick.bind(this);
    this.fetchGame = this.fetchGame.bind(this);
    this.fetchView = this.fetchView.bind(this);
  }

  componentDidMount() {
    this.fetchGame(this.gameId);
    this.fetchView(this.gameId, this.playerViewId);
  }

  fetchGame(gameId) {
    fetch(constants.API_ADDRESS + '/game?game_id=' + gameId, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        this.setState({
          gameName: response.game.game_name
        });
        if (response.game.is_game_over) {
          this.fetchGameWinner(response.game._id, response.game.winner_view_id);
        }
      }
    }).catch(error => console.log(error));
  }

  fetchGameWinner(gameId, winnerViewId) {
    fetch(constants.API_ADDRESS + '/game/view?game_id=' + gameId + '&view_id=' + winnerViewId, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        var message;
        var alertVariant;
        if (this.playerViewId === winnerViewId) {
          message = 'Winner winner chicken dinner';
          alertVariant = 'success';
        }
        this.setState({showAlert: true, alertMessage: message, alertVariant: alertVariant});
      }
    }).catch(error => console.log(error));
  }

  fetchView(gameId, playerViewId) {
    fetch(constants.API_ADDRESS + '/game/view?game_id=' + gameId + '&view_id=' + playerViewId, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        this.setState({
          playerName: response.game_views[0].player_name,
          squares: response.game_views[0].squares
        });
      }
    }).catch(error => console.log(error));
  }

  onTextChange(text, row, col) {
  }

  onSquareClick(row, col) {
  }

  render() {
    return (
      <div style={{padding: '10px'}}>
      <h1>{this.state.gameName}</h1>
      <br/>
      <Alert show={this.state.showAlert} variant={this.state.alertVariant}>{this.state.alertMessage}</Alert>
      <br/>
      <p>Viewing for player: {this.state.playerName}</p>
      <br/>
      <BingoBoard squares={this.state.squares} isEditable={false} isPlayable={false} />
      </div>
    );
  }
}

export default ViewGame;
