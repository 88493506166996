import React from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable'

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

class BingoBoard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log('foooo');
    console.log(this.props.squares);
  }

  onSquareClick(row, col) {
    if (this.props.isPlayable) {
      console.log('game on');
      this.props.onSquareClick(row, col);
    }
    console.log(row + ', ' + col);
  }

  render() {
    var unselectable = {
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',
      'width': '20%'
    }
    var squareStyle = {
      background:
         'linear-gradient(to top left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px), rgba(0,0,0,1) 50%, rgba(0,0,0,0) calc(50% + 0.8px), rgba(0,0,0,0) 100%), linear-gradient(to top right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px), rgba(0,0,0,1) 50%, rgba(0,0,0,0) calc(50% + 0.8px), rgba(0,0,0,0) 100%)'
    }
    var bingoSquares = [];

    for (const [rowIndex, row] of this.props.squares.entries()) {
      var rowSquares = [];
      for (const [colIndex, value] of row.entries()) {
        var borderStyle = {
        }
        var colStyle = value.isCrossed ? squareStyle : {};
        rowSquares.push(
          <td style={this.props.isEditable ? {width: '20%'} : unselectable}>
            <Col style={colStyle} onClick={() => this.onSquareClick(rowIndex, colIndex)}>
            <ContentEditable
              html={value.value}
              disabled={!this.props.isEditable}
              onChange={(event) => this.props.onTextChange(event.target.value, rowIndex, colIndex)}
              style={{height: '140px', 'font-size': '12px', 'text-align': 'center', display: 'flex', 'align-items': 'center', 'justify-content': 'center', cursor: this.props.isPlayable ? 'pointer': '' }}
            />
            </Col>
          </td>
        );
      }
      bingoSquares.push(
        <tr>
          {rowSquares}
        </tr>
      );
    }

    return (
      <div>
      <Table bordered>
      <tbody>
        {bingoSquares}
      </tbody>
      </Table>
      </div>
    );
  }
}

export default BingoBoard;
