import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import {FixedBottom} from 'react-fixed-bottom';

import CreateGame from './CreateGame.js';
import JoinGame from './JoinGame.js';
import PlayGame from './PlayGame.js';
import RecentGames from './RecentGames.js';
import ViewGame from './ViewGame.js';
import Home from './Home.js';

import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  var footerStyle = {
      backgroundColor: "#F8F8F8",
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      padding: "20px",
      width: "100%",
    }

  return (
    <div>
    <Router>
      <Container>
        <div id="app" style={{height:'100%'}}>
          <Navbar style={{'border-bottom':'1px solid #D3D3D3'}} expand="sm">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/recent_games">Recent Games</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <br/>
          <Switch>
            <Route path="/create">
              <CreateGame />
            </Route>
            <Route path="/game/:gameId/join" component={JoinGame} />
            <Route path="/game/:gameId/play/:playerId" component={PlayGame} />
            <Route path="/game/:gameId/view/:playerViewId" component={ViewGame} />

            <Route path="/privacy_policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms_of_service">
              <TermsOfService />
            </Route>

            <Route path="/recent_games" component={RecentGames} />
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <br/><br/><br/><br/><br/>
          </div>
        </Container>
    </Router>
    <FixedBottom offset={0}>
      <div style={footerStyle}>
        By using this website, you agree to our <a href='/privacy_policy'>Privacy Policy</a> and <a href='/terms_of_service'>Terms of Service</a>.
      </div>
    </FixedBottom>
    </div>
  );
}

export default App;
