import * as constants from './Constants.js';

import Cookies from 'js-cookie';
import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import BingoBoard from './BingoBoard.js';

class RecentGames extends React.Component {
  constructor(props) {
    super(props);
    var recentGames = Cookies.get(constants.COOKIE_RECENT_GAMES);
    if (recentGames == null) {
      recentGames = [];
    } else {
      recentGames = JSON.parse(recentGames);
    }
    this.state = {
      recentGames: recentGames,
    };
  }

  render() {
    var recentGames = [];

    for (const [index, game] of this.state.recentGames.entries()) {
      recentGames.push(
        <p><a href={'/game/' + game.gameId + '/play/' + game.playerId}>{game.gameName}</a></p>
      );
    }

    return (
      <div style={{padding: '10px'}}>
      <h1>Recent Games</h1>
      <br/>
      {recentGames.length == 0 ? (
        <p>No recent games.</p>
      ) : (
        recentGames
      )}
      </div>
    );
  }
}

export default RecentGames;
