import * as constants from './Constants.js';

import Cookies from 'js-cookie';
import React from 'react';

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";
import * as qs from 'query-string';

import BingoBoard from './BingoBoard.js';

function doesBoardHaveEmpty(rows) {
  for (const [rowIndex, row] of rows.entries()) {
    for (const [colIndex, value] of row.entries()) {
      if (value.value === '&nbsp' || value.value === '<br>' || value.value === '') {
        return true;
      }
      console.log('test', value.value);
    }
  }
  return false;
}

class CreateGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gameName: '',
      squares: [
        [{value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}],
        [{value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}],
        [{value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}],
        [{value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}],
        [{value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}, {value: '&nbsp', isCrossed: false}]
      ],
      shuffleForEachPlayer: false,
      uploadPublicTemplate: true,
      showAlert: false,
      alertMessage: '',
      alertVariant: 'success',
      isCurrentlySubmitting: false
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSquareClick = this.onSquareClick.bind(this);
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.sendCreateGame = this.sendCreateGame.bind(this);
  }

  componentDidMount() {
    let params = qs.parse(window.location.search);
    if (params.game != null) {
      let decodedGame = JSON.parse(decodeURIComponent(Cookies.get(constants.COOKIE_TEMP_GAME)));
      this.setState({gameName: decodedGame['gameName'], squares: decodedGame['squares'], shuffleForEachPlayer: true});
    }
    if (params.template != null) {
      this.fetchTemplate(params.template);
    }
  }

  onTextChange(text, row, col) {
      console.log('Left editor with text: ' + text + row + col);
      var squares = this.state.squares;
      squares[row][col]['value'] = text;
      this.setState({squares: squares});
  }

  onSquareClick(row, col) {
  }

  fetchTemplate(templateId) {
    fetch(constants.API_ADDRESS + '/game/templates?template=' + templateId, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        this.setState({
          gameName: response.templates[0].game_name,
          squares: response.templates[0].squares,
          shuffleForEachPlayer: response.templates[0].shuffle_for_each_player
        });
      }
    }).catch(error => console.log(error));
  }

  onSubmitButtonClick(event) {
    event.preventDefault();
    if (this.gameName.value === '') {
      this.setState({showAlert: true, alertMessage: 'Empty game name.', alertVariant: 'danger'});
      return;
    }
    if (doesBoardHaveEmpty(this.state.squares)) {
      this.setState({showAlert: true, alertMessage: 'Empty square in board.', alertVariant: 'danger'});
      return;
    }
    this.setState({isCurrentlySubmitting: true});
    this.sendCreateGame();
  }

  sendCreateGame() {
    fetch(constants.API_ADDRESS + '/game', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({
        'game_name': this.gameName.value,
        'squares': this.state.squares,
        'shuffle_for_each_player': this.state.shuffleForEachPlayer
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        var gameId = response.game_id;
        window.location.href = '/game/' + gameId + '/join';
      } else if (response.status === 'error') {
        this.setState({isCurrentlySubmitting: false, showAlert: true, alertMessage: 'Error creating game.', alertVariant: 'danger'});
      }
    });
    if (this.state.uploadPublicTemplate) {
      this.sendCreateTemplate();
    }
  }

  sendCreateTemplate() {
    fetch(constants.API_ADDRESS + '/game/templates', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({
        'game_name': this.gameName.value,
        'squares': this.state.squares,
        'shuffle_for_each_player': this.state.shuffleForEachPlayer,
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
    });
  }

  render() {
    return (
      <div style={{padding: '10px'}}>
        <h1>Make your edits, if desired</h1>
        <br/>
        <Form>
          <Form.Group>
            <Form.Label>Game Name</Form.Label>
            <Form.Control type="text" ref={(text) => this.gameName = text} defaultValue={this.state.gameName} />
            <br/>
            Shuffle board for each player?
            <Form.Control type="checkbox" checked={this.state.shuffleForEachPlayer} onChange={(event) => { var shuffleForEachPlayer = !this.state.shuffleForEachPlayer; this.setState({shuffleForEachPlayer: shuffleForEachPlayer})}} />
            Upload game board as template for public use?
            <Form.Control type="checkbox" checked={this.state.uploadPublicTemplate} onChange={(event) => { var uploadPublicTemplate = !this.state.uploadPublicTemplate; this.setState({uploadPublicTemplate: uploadPublicTemplate})}} />
          </Form.Group>
        </Form>
        <br/>
        <BingoBoard squares={this.state.squares} onTextChange={this.onTextChange} onSquareClick={this.onSquareClick} isEditable={true} isPlayable={false} />
        <br/>
        <Alert show={this.state.showAlert} variant={this.state.alertVariant}>{this.state.alertMessage}</Alert>
        <Button type="submit" disabled={this.state.isCurrentlySubmitting ? ('disabled') : ('')} onClick={this.onSubmitButtonClick}>Start</Button>
      </div>
    );
  }
}

export default CreateGame;
