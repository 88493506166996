import * as constants from './Constants.js';

import React from 'react';

import Form from "react-bootstrap/Form";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: []
    };
    this.searchQuery = '';
    this.allTemplates = [];
    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
  }

  componentDidMount() {
    this.fetchTemplates(
      (templates) => {
        this.allTemplates = templates;
        if (this.searchQuery === '') {
          this.setState({templates: this.allTemplates});
        }
      }
    );
  }

  fetchTemplates(callback, searchQuery) {
    var URL = constants.API_ADDRESS + '/game/templates';
    if (searchQuery != null) {
      URL = URL + '?q=' + searchQuery;
    }
    fetch(URL, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        callback(response.templates);
      }
    }).catch(error => console.log(error));
  }

  onSearchFieldChange(event) {
    this.searchQuery = event.target.value;
    if (event.target.value === '') {
      this.setState({templates: this.allTemplates});
    } else {
      this.fetchTemplates(
        (templates) => this.setState({templates: templates}),
        event.target.value
      );
    }
  }

  render() {
    var templates = [];

    for (const [index, template] of this.state.templates.entries()) {
      templates.push(
        <p><a href={'/create?template=' + template._id}>{template.game_name}</a></p>
      );
    }

    return (
      <div>
        <h1>Start your bingo game by first selecting a template.</h1>
        <br/>
        <p>Create a <a href='/create'>blank template</a></p>
        <br/>
        <p>Or, search for an existing template:</p>
        <Form>
          <Form.Group>
            <Form.Control type="text" onChange={this.onSearchFieldChange} placeholder="Search" />
          </Form.Group>
        </Form>
        {templates.length == 0 ? (
          <p>No results found.</p>
        ) : (
          templates
        )}
      </div>
    );
  }
}

export default Home;
