import * as constants from './Constants.js';

import Cookies from 'js-cookie';
import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import BingoBoard from './BingoBoard.js';
import { isHttps } from './Util.js';

class PlayGame extends React.Component {
  constructor(props) {
    super(props);
    this.gameId = this.props.match.params.gameId;
    this.playerId = this.props.match.params.playerId;
    this.state = {
      gameName: '',
      squares: [],
      otherPlayers: [],
      isGameOver: true,
      showAlert: false,
      alertMessage: '',
      alertVariant: 'success',
    };
    this.onTextChange = this.onTextChange.bind(this);
    this.onSquareClick = this.onSquareClick.bind(this);
    this.fetchGame = this.fetchGame.bind(this);
    this.fetchGamePlayer = this.fetchGamePlayer.bind(this);
    this.fetchAllGamePlayers = this.fetchAllGamePlayers.bind(this);
    this.fetchGameWinner = this.fetchGameWinner.bind(this);
    this.sendSquareClick = this.sendSquareClick.bind(this);
    this.onCreateNewGame = this.onCreateNewGame.bind(this);
  }

  componentDidMount() {
    this.fetchGame(this.gameId);
    this.fetchGamePlayer(this.gameId, this.playerId);
  }

  fetchGame(gameId) {
    fetch(constants.API_ADDRESS + '/game?game_id=' + gameId, {
      method: 'GET',
      headers: { Pragma: 'no-cache, no-store', 'Cache-Control': 'no-cache, no-store'},
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        this.setState({
          gameName: response.game.game_name,
          isGameOver: response.game.is_game_over
        });
        if (response.game.is_game_over) {
          this.fetchGameWinner(response.game._id, response.game.winner_view_id);
        }

        var recentGames = Cookies.get(constants.COOKIE_RECENT_GAMES);
        if (recentGames == null) {
          recentGames = [];
        } else {
          recentGames = JSON.parse(recentGames);
        }
        var currentIndex = -1;
        var currentGame;
        for (const [index, game] of recentGames.entries()) {
          if (game.gameId === this.gameId) {
            currentIndex = index;
            currentGame = game;
            break;
          }
        }
        if (currentIndex == -1) {
          recentGames.unshift({gameId: this.gameId, gameName: this.state.gameName, playerId: this.playerId});
        } else {
          recentGames.splice(currentIndex, 1);
          recentGames.unshift(currentGame);
        }
        if (recentGames.length > constants.RECENT_GAMES_MAX_LENGTH) {
          recentGames = recentGames.slice(0, constants.RECENT_GAMES_MAX_LENGTH);
        }
        Cookies.set(constants.COOKIE_RECENT_GAMES, JSON.stringify(recentGames), {secure: isHttps(), expires: 365});
      }
    }).catch(error => console.log(error));
  }

  fetchGamePlayer(gameId, playerId) {
    fetch(constants.API_ADDRESS + '/game/player?game_id=' + gameId + '&player_id=' + playerId, {
      method: 'GET',
      headers: { Pragma: 'no-cache, no-store', 'Cache-Control': 'no-cache, no-store'},
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        this.setState({squares: response.game_player.squares});
        this.playerViewId = response.game_player.view_id;
        this.fetchAllGamePlayers(gameId);
      }
    }).catch(error => console.log(error));
  }

  fetchAllGamePlayers(gameId) {
    fetch(constants.API_ADDRESS + '/game/view?game_id=' + gameId, {
      method: 'GET',
      headers: { Pragma: 'no-cache, no-store', 'Cache-Control': 'no-cache, no-store'},
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        var otherGamePlayers = [];
        for (const [index, player] of response.game_views.entries()) {
          otherGamePlayers.push({viewId: player.view_id, name: player.player_name});
        }
        this.setState({otherPlayers: otherGamePlayers});
      }
    }).catch(error => console.log(error));
  }

  fetchGameWinner(gameId, winnerViewId) {
    fetch(constants.API_ADDRESS + '/game/view?game_id=' + gameId + '&view_id=' + winnerViewId, {
      method: 'GET',
      headers: { Pragma: 'no-cache, no-store', 'Cache-Control': 'no-cache, no-store'},
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        var message;
        var alertVariant;
        if (this.playerViewId === winnerViewId) {
          message = 'Congratulations, you\'ve won!';
          alertVariant = 'success';
        } else {
          message = 'Tough scene, ' + response.game_views[0].player_name + ' has already won.';
          alertVariant = 'danger';
        }
        this.setState({showAlert: true, alertMessage: message, alertVariant: alertVariant});
      }
    }).catch(error => console.log(error));
  }

  sendSquareClick(gameId, playerId, playerViewId, squares) {
    fetch(constants.API_ADDRESS + '/game/player', {
      method: 'PUT',
      crossDomain: true,
      body: JSON.stringify({
        'game_id': gameId,
        'player_id': playerId,
        'player_view_id': playerViewId,
        'squares': squares
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        // Fetch the game to see if the current player has won the game.
        this.fetchGame(gameId);
      }
    });
  }

  onTextChange(text, row, col) {
      console.log('Left editor with text: ' + text + row + col);
  }

  onSquareClick(row, col) {
      console.log('Clicked: ' + row + col);
      var squares = this.state.squares;
      squares[row][col].isCrossed = !squares[row][col].isCrossed;
      this.setState({squares: squares});
      this.sendSquareClick(this.gameId, this.playerId, this.playerViewId, this.state.squares);
  }

  onCreateNewGame() {
    var squares = this.state.squares;
    for (const [rowIndex, row] of squares.entries()) {
      for (const [colIndex, col] of row.entries()) {
        squares[rowIndex][colIndex].isCrossed = false;
      }
    }
    var game = {};
    game['gameName'] = this.state.gameName;
    game['squares'] = squares;
    var encodedGame = encodeURIComponent(JSON.stringify(game));
    Cookies.set(constants.COOKIE_TEMP_GAME, encodedGame);
    window.location.href = '/create?game=game';
  }

  render() {
    var otherPlayers = [];

    for (const [index, player] of this.state.otherPlayers.entries()) {
      // We retrieve the other players after getting the current player view ID, so we won't have a race condition here.
      if (player.viewId != this.playerViewId) {
        otherPlayers.push(
          <p><a href={'/game/' + this.gameId + '/view/' + player.viewId}>{player.name}</a></p>
        );
      }
    }

    return (
      <div style={{padding: '10px'}}>
      <h1>{this.state.gameName}</h1>
      <br/>
      <Alert show={this.state.showAlert} variant={this.state.alertVariant}>{this.state.alertMessage}</Alert>
      <br/>
      <p>You can return to this game anytime using the URL, so make sure to keep it.</p>
      <br/>
      <BingoBoard squares={this.state.squares} onTextChange={this.onTextChange} onSquareClick={this.onSquareClick} isEditable={false} isPlayable={!this.state.isGameOver} />
      <br/>
      <p>Get your friends to join using this link: <a href={constants.HOSTNAME + '/game/' + this.gameId + '/join'}>{constants.HOSTNAME + '/game/' + this.gameId + '/join'}</a></p>
      <br/>
      <h2>Other Players' Boards:</h2>
      {otherPlayers.length == 0 ? (
        <p>No other players.</p>
      ) : (
        otherPlayers
      )}
      <br/>
      <Button type="submit" onClick={this.onCreateNewGame}>Start new game with this template</Button>
      <br/>
      </div>
    );
  }
}

export default PlayGame;
