import * as constants from './Constants.js';

import React from 'react';

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";

class JoinGame extends React.Component {
  constructor(props) {
    super(props);
    this.gameId = this.props.match.params.gameId;
    this.state = {
      showAlert: false,
      alertMessage: '',
      alertVariant: 'success',
      isCurrentlySubmitting: false
    };
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.sendJoinGame = this.sendJoinGame.bind(this);
  }

  onSubmitButtonClick(event) {
    event.preventDefault();
    if (this.playerName.value === '') {
      this.setState({showAlert: true, alertMessage: 'Empty name.', alertVariant: 'danger'});
      return;
    }
    this.setState({isCurrentlySubmitting: true});
    this.sendJoinGame(this.gameId, this.playerName.value);
  }

  sendJoinGame(gameId, playerName) {
    fetch(constants.API_ADDRESS + '/game/player', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({
        'game_id': gameId,
        'player_name': playerName
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status === 'ok') {
        var playerId = response.player_id;
        window.location.href = '/game/' + this.gameId + '/play/' + playerId;
      } else if (response.status === 'error') {
        this.setState({isCurrentlySubmitting: false, showAlert: true, alertMessage: 'Error joining game.', alertVariant: 'danger'});
      }
    });
  }

  render() {
    return (
      <div>
        <h1>Join this game by first entering your name:</h1>
        <br/>
        <Form>
          <Form.Group>
            <Form.Label>Your name:</Form.Label>
            <Form.Control type="text" ref={(text) => this.playerName = text} placeholder="" />
          </Form.Group>
        </Form>
        <Alert show={this.state.showAlert} variant={this.state.alertVariant}>{this.state.alertMessage}</Alert>
        <Button type="submit" disabled={this.state.isCurrentlySubmitting ? ('disabled') : ('')} onClick={this.onSubmitButtonClick}>Submit</Button>
      </div>
    );
  }
}

export default JoinGame;
