// export const API_ADDRESS = 'http://localhost:1738';
// TODO: stop being cheap and buy a dedicated domain instead of sharing servers.
// Port 443 is already used by another project :(
export const API_ADDRESS = 'https://y-backend.com';
// export const HOSTNAME = 'http://localhost:3000';
export const HOSTNAME = 'https://bingo.y-backend.com';

export const COOKIE_RECENT_GAMES = 'COOKIE_RECENT_GAMES';
export const COOKIE_TEMP_GAME = 'COOKIE_TEMP_GAME';

export const RECENT_GAMES_MAX_LENGTH = 20;
